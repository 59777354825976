
  import ScreenSize from '@/mixins/ScreenSize';
  import {mapSectionComponents} from '@/helper/SectionHelper';
  import LazyHydrate from 'vue-lazy-hydration';

  export default {
    name: 'Error',
    components: {
      LazyHydrate,
      ErrorWithCode: () => import('@/components/sections/ErrorWithCode'),
    },
    mixins: [ScreenSize],
    layout(context) {
      let layout = 'default';
      if (context.route.fullPath.includes('/recruiting')) {
        layout = 'organization';
      }
      return layout;
    },
    props: {
      error: {
        type: Object,
        default: null,
      },
    },
    data() {
      return {
        metaData: {},
        seoContentConfig: {},
        sections: [],
        noErrorPage: false,
      };
    },
    head() {
      const title =
        this.error.statusCode === 404 ? this.pageNotFound : this.otherError;
      return {
        title,
      };
    },
    async created() {
      let errorPage = 'error';
      if (this.$nuxt.$route.fullPath.includes('/recruiting')) {
        errorPage = 'error-organization';
      } else if (
        this.error.statusCode === 410 &&
        this.error.message === 'JobPosting is inactive!'
      ) {
        errorPage = 'error-jobposting-inactive';
      }

      // Query page from Strapi; strapi-change for findOne is required!
      let page = {};
      page = await this.$cachedStrapi.findOne('pages', errorPage).catch((e) => {
        this.noErrorPage = true;
        if (e.statusCode !== 404) {
          throw e;
        }
      });

      let sections = [];
      if (page && page.sections) {
        sections = await mapSectionComponents(
          page.sections,
          async (section) => {
            switch (section.component) {
              case 'SectionSeoContent':
                if (
                  section.seo_content_config &&
                  section.seo_content_config.seo_contents &&
                  section.seo_content_config.seo_contents[0]
                ) {
                  section.seoContent = await this.$cachedStrapi.findOne(
                    'seo-contents',
                    section.seo_content_config.seo_contents[0].id
                  );
                }
                break;
              case 'SectionErrorWithCode':
                section.statusCode = this.error.statusCode;
                section.message = this.error.message;
                break;
            }
          }
        );
      }

      const metaData = page && page.meta ? page.meta : {};
      const seoContentConfig =
        page && page.seo_content_config ? page.seo_content_config : {};

      this.metaData = metaData;
      this.seoContentConfig = seoContentConfig;
      this.sections = sections;
    },
  };
