
  import {fixedRouteSlugs} from '@/routes.config';
  import LazyHydrate from 'vue-lazy-hydration';
  import ScreenSize from '@/mixins/ScreenSize';

  export default {
    name: 'DefaultFooter',
    components: {
      LazyHydrate,
      SocialIcons: () => import('@/components/SocialIcons'),
      LoggedUserMobileMenuDialog: () =>
        import('@/components/elements/LoggedUserMobileMenuDialog'),
    },
    mixins: [ScreenSize],
    props: {
      loggedIn: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    serverCacheKey(props) {
      // Deaktiviere das Caching, wenn der Benutzer eingeloggt ist
      if (props.loggedIn) {
        return false;
      }

      // Ansonsten generiere einen eindeutigen Cache-Schlüssel
      return 'footer';
    },
    data: () => ({
      // https://www.promotionbasis.de/cms/footer-navigation
      footerNavigation: {
        id: 1,
        created_at: '2021-03-12T18:50:12.703Z',
        updated_at: '2023-12-08T07:46:50.150Z',
        items: [
          {
            id: 15,
            internal_title: 'Für Jobanbieter',
            main: {
              id: 42,
              Text: null,
              Url: null,
              Target: null,
              Title: null,
              Icon: null,
              text: 'Für Jobanbieter',
              icon: null,
              class: null,
              href: null,
              target: null,
              route: null,
              page: null,
              article: null,
              activity: null,
              city: null,
              title: null,
            },
            items: [
              {
                id: 228,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Job inserieren',
                icon: null,
                class: 'white--text',
                href: null,
                target: '_blank',
                route: null,
                page: {
                  id: 93,
                  InternalTitle: 'Job inserieren',
                  slug: 'job-inserieren',
                  InternalDescription: null,
                  Layout: null,
                  Active: null,
                  created_at: '2023-09-29T10:02:23.014Z',
                  updated_at: '2023-10-10T07:03:29.439Z',
                  published_at: '2023-09-29T10:55:37.899Z',
                  views: null,
                  scope: 'promotionbasis_organization',
                  show_seo_content: null,
                },
                article: null,
                activity: null,
                city: null,
                title: 'Job inserieren',
              },
              {
                id: 229,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Sonderwerbeformen',
                icon: null,
                class: 'white--text',
                href: null,
                target: '_blank',
                route: null,
                page: {
                  id: 96,
                  InternalTitle: 'Sonderwerbeformen',
                  slug: 'sonderwerbeformen',
                  InternalDescription: null,
                  Layout: null,
                  Active: null,
                  created_at: '2023-10-02T06:26:40.932Z',
                  updated_at: '2023-10-25T13:24:10.826Z',
                  published_at: '2023-10-02T06:45:22.078Z',
                  views: null,
                  scope: 'promotionbasis_organization',
                  show_seo_content: null,
                },
                article: null,
                activity: null,
                city: null,
                title: 'Sonderwerbeformen',
              },
              {
                id: 230,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Bewerberdatenbank',
                icon: null,
                class: 'white--text',
                href: null,
                target: '_blank',
                route: null,
                page: {
                  id: 97,
                  InternalTitle: 'Bewerberdatenbank',
                  slug: 'bewerberdatenbank',
                  InternalDescription: null,
                  Layout: null,
                  Active: null,
                  created_at: '2023-10-02T08:06:39.989Z',
                  updated_at: '2023-10-25T13:23:43.954Z',
                  published_at: '2023-10-02T08:11:38.731Z',
                  views: null,
                  scope: 'promotionbasis_organization',
                  show_seo_content: null,
                },
                article: null,
                activity: null,
                city: null,
                title: 'Bewerberdatenbank',
              },
              {
                id: 231,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Stellenanzeigen-Pakete',
                icon: null,
                class: 'white--text',
                href: null,
                target: '_blank',
                route: null,
                page: {
                  id: 94,
                  InternalTitle: 'Stellenanzeigen-Pakete',
                  slug: 'stellenanzeigen-pakete',
                  InternalDescription: null,
                  Layout: null,
                  Active: null,
                  created_at: '2023-09-29T12:15:36.954Z',
                  updated_at: '2023-10-25T13:25:07.893Z',
                  published_at: '2023-09-29T12:29:50.138Z',
                  views: null,
                  scope: 'promotionbasis_organization',
                  show_seo_content: null,
                },
                article: null,
                activity: null,
                city: null,
                title: 'Stellenanzeigen-Pakete',
              },
            ],
          },
          {
            id: 16,
            internal_title: 'Links',
            main: {
              id: 30,
              Text: null,
              Url: null,
              Target: null,
              Title: null,
              Icon: null,
              text: 'Links',
              icon: null,
              class: null,
              href: null,
              target: null,
              route: null,
              page: null,
              article: null,
              activity: null,
              city: null,
              title: null,
            },
            items: [
              {
                id: 89,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Jobs anzeigen',
                icon: null,
                class: null,
                href: null,
                target: null,
                route: null,
                page: {
                  id: 9,
                  InternalTitle: 'Joblisting Indexseite',
                  slug: 'jobs',
                  InternalDescription: null,
                  Layout: null,
                  Active: false,
                  created_at: '2021-02-15T04:33:26.565Z',
                  updated_at: '2023-05-04T07:48:19.383Z',
                  published_at: '2021-02-15T04:35:06.044Z',
                  views: '3700',
                  scope: 'promotionbasis_candidate',
                  show_seo_content: null,
                },
                article: null,
                activity: null,
                city: null,
                title: null,
              },
              {
                id: 90,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Jobs nach Stadt',
                icon: null,
                class: null,
                href: null,
                target: null,
                route: null,
                page: {
                  id: 51,
                  InternalTitle: 'Landingpage Städte',
                  slug: 'landingpages-cities',
                  InternalDescription: null,
                  Layout: null,
                  Active: null,
                  created_at: '2021-09-17T03:53:56.544Z',
                  updated_at: '2023-08-21T10:36:27.455Z',
                  published_at: '2021-09-17T03:54:00.108Z',
                  views: '169',
                  scope: 'promotionbasis_candidate',
                  show_seo_content: null,
                },
                article: null,
                activity: null,
                city: null,
                title: null,
              },
              {
                id: 91,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Jobs nach Tätigkeit',
                icon: null,
                class: null,
                href: null,
                target: null,
                route: null,
                page: {
                  id: 3,
                  InternalTitle: 'Landingpage Tätigkeiten',
                  slug: 'landingpages-activities',
                  InternalDescription: null,
                  Layout: null,
                  Active: false,
                  created_at: '2021-02-09T07:45:26.358Z',
                  updated_at: '2023-08-21T10:35:32.976Z',
                  published_at: '2021-02-09T07:45:28.836Z',
                  views: '547',
                  scope: 'promotionbasis_candidate',
                  show_seo_content: null,
                },
                article: null,
                activity: null,
                city: null,
                title: null,
              },
              {
                id: 128,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Promotionforum',
                icon: null,
                class: null,
                href: 'https://www.promotionforum.de',
                target: '_blank',
                route: null,
                page: null,
                article: null,
                activity: null,
                city: null,
                title: null,
              },
            ],
          },
          {
            id: 17,
            internal_title: 'Sonstiges',
            main: {
              id: 31,
              Text: null,
              Url: null,
              Target: null,
              Title: null,
              Icon: null,
              text: 'Sonstiges',
              icon: null,
              class: null,
              href: null,
              target: '',
              route: null,
              page: null,
              article: null,
              activity: null,
              city: null,
              title: null,
            },
            items: [
              {
                id: 136,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Wiki',
                icon: null,
                class: null,
                href: null,
                target: null,
                route: null,
                page: {
                  id: 17,
                  InternalTitle: 'Wiki-Übersicht',
                  slug: 'wikis',
                  InternalDescription: null,
                  Layout: null,
                  Active: null,
                  created_at: '2021-04-29T10:56:57.754Z',
                  updated_at: '2023-10-10T06:48:31.229Z',
                  published_at: '2021-04-29T10:57:00.587Z',
                  views: '96',
                  scope: 'promotionbasis',
                  show_seo_content: null,
                },
                article: null,
                activity: null,
                city: null,
                title: null,
              },
              {
                id: 238,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Messen & Events',
                icon: null,
                class: null,
                href: null,
                target: null,
                route: null,
                page: {
                  id: 116,
                  InternalTitle: 'Messen & Events Übersicht',
                  slug: 'events',
                  InternalDescription: null,
                  Layout: null,
                  Active: null,
                  created_at: '2024-04-30T07:25:39.923Z',
                  updated_at: '2024-05-02T09:20:11.534Z',
                  published_at: '2024-04-30T07:26:42.058Z',
                  views: null,
                  scope: 'promotionbasis_candidate',
                  show_seo_content: null,
                },
                article: null,
                activity: null,
                city: null,
                title: 'Messen, Festivals, Events finden',
              },
              {
                id: 162,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Promoter Erfahrungen',
                icon: null,
                class: null,
                href: null,
                target: null,
                route: null,
                page: {
                  id: 81,
                  InternalTitle: 'Testimonial Übersicht',
                  slug: 'promoter-erfahrungen',
                  InternalDescription: null,
                  Layout: null,
                  Active: null,
                  created_at: '2022-10-18T07:35:00.548Z',
                  updated_at: '2022-10-25T07:34:58.905Z',
                  published_at: '2022-10-25T07:21:10.761Z',
                  views: null,
                  scope: 'promotionbasis_candidate',
                  show_seo_content: null,
                },
                article: null,
                activity: null,
                city: null,
                title: null,
              },
              {
                id: 124,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Alle Partner',
                icon: null,
                class: null,
                href: null,
                target: null,
                route: null,
                page: {
                  id: 56,
                  InternalTitle: 'Partner ',
                  slug: 'partner',
                  InternalDescription: null,
                  Layout: null,
                  Active: null,
                  created_at: '2021-10-25T10:31:27.779Z',
                  updated_at: '2022-06-29T07:21:38.252Z',
                  published_at: '2021-10-25T11:49:53.528Z',
                  views: null,
                  scope: 'promotionbasis_candidate',
                  show_seo_content: null,
                },
                article: null,
                activity: null,
                city: null,
                title: null,
              },
            ],
          },
          {
            id: 18,
            internal_title: 'Service',
            main: {
              id: 43,
              Text: null,
              Url: null,
              Target: null,
              Title: null,
              Icon: null,
              text: 'Service',
              icon: null,
              class: null,
              href: null,
              target: null,
              route: null,
              page: null,
              article: null,
              activity: null,
              city: null,
              title: null,
            },
            items: [
              {
                id: 130,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Deine Vorteile',
                icon: null,
                class: null,
                href: null,
                target: null,
                route: null,
                page: {
                  id: 61,
                  InternalTitle: 'Vorteile',
                  slug: 'vorteile',
                  InternalDescription: null,
                  Layout: null,
                  Active: null,
                  created_at: '2022-01-21T10:56:50.753Z',
                  updated_at: '2023-04-19T20:40:17.597Z',
                  published_at: '2022-01-28T08:36:35.778Z',
                  views: null,
                  scope: 'promotionbasis_candidate',
                  show_seo_content: null,
                },
                article: null,
                activity: null,
                city: null,
                title: null,
              },
              {
                id: 115,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Kontakt',
                icon: null,
                class: null,
                href: null,
                target: null,
                route: null,
                page: {
                  id: 54,
                  InternalTitle: 'Kontakt',
                  slug: 'kontakt',
                  InternalDescription: null,
                  Layout: null,
                  Active: null,
                  created_at: '2021-09-23T11:53:46.221Z',
                  updated_at: '2022-06-29T06:12:07.692Z',
                  published_at: '2021-09-23T11:53:50.009Z',
                  views: '352',
                  scope: 'promotionbasis_candidate',
                  show_seo_content: null,
                },
                article: null,
                activity: null,
                city: null,
                title: null,
              },
              {
                id: 123,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Hilfe & Support',
                icon: null,
                class: null,
                href: null,
                target: null,
                route: null,
                page: {
                  id: 1,
                  InternalTitle: 'Artikel-Übersicht',
                  slug: 'articles',
                  InternalDescription: null,
                  Layout: null,
                  Active: false,
                  created_at: '2021-02-03T19:33:37.894Z',
                  updated_at: '2022-06-29T06:06:11.722Z',
                  published_at: '2021-02-04T14:11:56.790Z',
                  views: '1660',
                  scope: 'promotionbasis_candidate',
                  show_seo_content: true,
                },
                article: null,
                activity: null,
                city: null,
                title: null,
              },
              {
                id: 126,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Über uns',
                icon: null,
                class: null,
                href: null,
                target: null,
                route: null,
                page: {
                  id: 66,
                  InternalTitle: 'Über uns',
                  slug: 'ueber-uns',
                  InternalDescription: null,
                  Layout: null,
                  Active: null,
                  created_at: '2022-04-24T18:09:30.263Z',
                  updated_at: '2023-10-17T08:22:29.419Z',
                  published_at: '2022-06-01T07:16:33.988Z',
                  views: null,
                  scope: 'promotionbasis_candidate',
                  show_seo_content: null,
                },
                article: null,
                activity: null,
                city: null,
                title: null,
              },
            ],
          },
          {
            id: 26,
            internal_title: 'Rechtliches',
            main: {
              id: 111,
              Text: null,
              Url: null,
              Target: null,
              Title: null,
              Icon: null,
              text: 'Rechtliches',
              icon: null,
              class: null,
              href: null,
              target: null,
              route: null,
              page: null,
              article: null,
              activity: null,
              city: null,
              title: null,
            },
            items: [
              {
                id: 112,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Impressum',
                icon: null,
                class: null,
                href: null,
                target: null,
                route: null,
                page: {
                  id: 15,
                  InternalTitle: 'Impressum',
                  slug: 'impressum',
                  InternalDescription: null,
                  Layout: null,
                  Active: null,
                  created_at: '2021-04-22T18:02:18.300Z',
                  updated_at: '2023-10-09T13:42:44.850Z',
                  published_at: '2021-04-22T18:02:21.580Z',
                  views: '113',
                  scope: 'promotionbasis',
                  show_seo_content: null,
                },
                article: null,
                activity: null,
                city: null,
                title: null,
              },
              {
                id: 114,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Datenschutzhinweise',
                icon: null,
                class: null,
                href: null,
                target: null,
                route: null,
                page: {
                  id: 57,
                  InternalTitle: 'Datenschutz',
                  slug: 'datenschutz',
                  InternalDescription: null,
                  Layout: null,
                  Active: null,
                  created_at: '2021-11-10T12:28:06.832Z',
                  updated_at: '2022-06-29T06:07:14.153Z',
                  published_at: '2021-11-10T12:28:25.910Z',
                  views: null,
                  scope: 'promotionbasis_candidate',
                  show_seo_content: null,
                },
                article: null,
                activity: null,
                city: null,
                title: null,
              },
              {
                id: 113,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'AGB & Nutzungsbedingungen',
                icon: null,
                class: null,
                href: null,
                target: null,
                route: null,
                page: {
                  id: 53,
                  InternalTitle: 'AGB & Nutzungsbedingungen',
                  slug: 'agb-and-nutzungsbedingungen',
                  InternalDescription: null,
                  Layout: null,
                  Active: null,
                  created_at: '2021-09-22T13:00:52.123Z',
                  updated_at: '2023-12-07T13:18:12.026Z',
                  published_at: '2021-09-22T13:00:57.238Z',
                  views: '50',
                  scope: 'promotionbasis_candidate',
                  show_seo_content: null,
                },
                article: null,
                activity: null,
                city: null,
                title: null,
              },
            ],
          },
        ],
        socials: [
          {
            id: 44,
            Text: null,
            Url: null,
            Target: null,
            Title: null,
            Icon: null,
            text: 'Facebook',
            icon: 'fab fa-facebook-f',
            class: 'white--text',
            href: 'https://www.facebook.com/promotionbasis',
            target: '_blank',
            route: null,
            page: null,
            article: null,
            activity: null,
            city: null,
            title: null,
          },
          {
            id: 45,
            Text: null,
            Url: null,
            Target: null,
            Title: null,
            Icon: null,
            text: 'Instagram',
            icon: 'fab fa-instagram',
            class: 'white--text',
            href: 'https://www.instagram.com/promotionbasis/',
            target: '_blank',
            route: null,
            page: null,
            article: null,
            activity: null,
            city: null,
            title: null,
          },
          {
            id: 46,
            Text: null,
            Url: null,
            Target: null,
            Title: null,
            Icon: null,
            text: 'Twitter',
            icon: 'fab fa-twitter',
            class: 'white--text',
            href: 'https://twitter.com/promotionbasis',
            target: '_blank',
            route: null,
            page: null,
            article: null,
            activity: null,
            city: null,
            title: null,
          },
          {
            id: 120,
            Text: null,
            Url: null,
            Target: null,
            Title: null,
            Icon: null,
            text: 'Google',
            icon: 'fab fa-google',
            class: 'white--text',
            href: 'https://www.google.com/maps/place/promotionbasis.de+(PB)/@54.3146046,10.1278915,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0x3795963811a57b2f!8m2!3d54.3146046!4d10.1300802?authuser=1&shorturl=1',
            target: '_blank',
            route: null,
            page: null,
            article: null,
            activity: null,
            city: null,
            title: null,
          },
        ],
      },
      menuItems: [],
      iconColor: 'white--text',
      panel: [0, 1, 2, 3, 4],
    }),
    mounted() {
      if (this.isMobile) {
        this.panel = [];
      }
    },
    async created() {
      if (
        this.footerNavigation.items &&
        this.footerNavigation.items.length > 0
      ) {
        this.footerNavigation.items.forEach((menuItem) => {
          // prepare main menu item
          const mainMenuItem = this.prepareMenuItem(menuItem.main);
          mainMenuItem.items = [];

          // prepare menu sub items
          if (menuItem.items && menuItem.items.length > 0) {
            menuItem.items.forEach((subItem) => {
              const subMenuItem = this.prepareMenuItem(subItem);
              mainMenuItem.items.push(subMenuItem);
            });
          }

          this.menuItems.push(mainMenuItem);
        });
      }
    },
    methods: {
      changeCookies() {
        this.$store.dispatch('cookieConsent/showCookieConsentBanner');
      },
      prepareMenuItem(item) {
        const menuItem = {
          name: item.text,
        };

        // prepare route for menu item
        let route = null;
        if (item.route) {
          route = item.route;
        } else if (item.page && item.page.slug) {
          if (fixedRouteSlugs.includes(item.page.slug)) {
            route = {
              name: item.page.slug,
            };
          } else if (item.page.scope === process.env.SCOPE_ORGANIZATION) {
            route = {
              name: 'organization-slug',
              params: {slug: item.page.slug},
            };
          } else {
            route = {
              name: 'slug',
              params: {slug: item.page.slug},
            };
          }
        } else if (item.article) {
          route = {
            name: 'articles-category-article',
            params: {
              category: item.article.article_category.slug,
              article: item.article.slug,
            },
          };
        } else if (item.activity) {
          route = {
            name: 'landingpages-activities-activity',
            params: {
              activity: item.activity.slug,
            },
          };
        } else if (item.city) {
          route = {
            name: 'landingpages-cities-city',
            params: {
              city: item.city.slug,
            },
          };
        } else if (item.href) {
          menuItem.href = item.href;
          menuItem.target = item.target;
        }

        menuItem.route = route;

        menuItem.class = item.class;
        menuItem.icon = item.icon;
        menuItem.title = item.title;

        return menuItem;
      },
    },
  };
