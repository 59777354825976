import {
  checkIfObjectIsEmpty,
  checkIfObjectHasMoreThen4Items,
} from '@/helper/ObjectHelper';

export function calculateProfileCompleteness(profile) {
  let maxValue = 0;
  let value = 0;

  // personal-data
  maxValue++;
  if (profile.lastName) {
    value++;
  }
  maxValue++;
  if (profile.firstName) {
    value++;
  }
  maxValue++;
  if (profile.gender) {
    value++;
  }
  maxValue++;
  if (profile.birthDate) {
    value++;
  }
  maxValue++;
  if (profile.nationality) {
    value++;
  }
  maxValue++;
  if (profile.profileSummary) {
    value++;
  }

  // appearance
  maxValue++;
  if (profile.bodyHeight) {
    value++;
  }
  maxValue++;
  if (profile.sizeOfClothing) {
    value++;
  }
  maxValue++;
  if (profile.sizeOfShirt) {
    value++;
  }
  maxValue++;
  if (profile.sizeOfShoe) {
    value++;
  }
  maxValue++;
  if (profile.sizeOfPants) {
    value++;
  }
  maxValue++;
  if (profile.hairColor) {
    value++;
  }
  maxValue++;
  if (profile.hairLength) {
    value++;
  }
  maxValue++;
  if (profile.eyeColor) {
    value++;
  }

  // work-preference
  maxValue++;
  if (profile.preferenceSummary) {
    value++;
  }
  maxValue++;
  if (profile.preferredSalary) {
    value++;
  }
  maxValue++;
  if (checkIfObjectIsEmpty(profile.preferredActivities) === false) {
    value++;
  }
  maxValue++;
  if (checkIfObjectIsEmpty(profile.preferredIndustries) === false) {
    value++;
  }
  maxValue++;
  if (checkIfObjectIsEmpty(profile.preferredWorkingTimes) === false) {
    value++;
  }
  maxValue++;
  if (checkIfObjectIsEmpty(profile.preferredWorkingPlaces) === false) {
    value++;
  }
  maxValue++;
  if (checkIfObjectIsEmpty(profile.preferredEmploymentTypes) === false) {
    value++;
  }

  // mobility
  maxValue++;
  if (profile.ownCar) {
    value++;
  }
  maxValue++;
  if (profile.carType) {
    value++;
  }
  maxValue++;
  if (checkIfObjectIsEmpty(profile.drivingLicenses) === false) {
    value++;
  }

  // tax
  maxValue++;
  if (profile.taxNumber) {
    value++;
  }
  maxValue++;
  if (profile.taxOffice) {
    value++;
  }
  maxValue++;
  if (profile.salesTaxIdentificationNumber) {
    value++;
  }
  maxValue++;
  if (profile.denomination) {
    value++;
  }
  /*maxValue++;
  if (profile.salesTaxDeductible) {
    value++;
  }
  maxValue++;
  if (profile.taxSmallBusiness) {
    value++;
  }*/
  maxValue++;
  if (profile.jobEmploymentStatus) {
    value++;
  }
  maxValue++;
  if (
    profile.jobEmploymentStatusDateFrom &&
    (profile.jobEmploymentStatusDateUntil ||
      profile.jobEmploymentStatusOpenEnded)
  ) {
    value++;
  }
  maxValue++;
  if (profile.shortTermEmploymentWorkingDaysCurrentYear) {
    value++;
  }
  maxValue++;
  if (profile.shortTermEmploymentWorkingDaysLastYear) {
    value++;
  }
  maxValue++;
  if (profile.healthInsuranceType) {
    value++;
  }
  maxValue++;
  if (profile.healthInsuranceName) {
    value++;
  }
  maxValue++;
  if (profile.healthInsuranceNumber) {
    value++;
  }

  // bank-data
  maxValue++;
  if (profile.accountHolder) {
    value++;
  }
  maxValue++;
  if (profile.bankName) {
    value++;
  }
  maxValue++;
  if (profile.iban) {
    value++;
  }
  /*
  maxValue++;
  if (profile.bic) {
    value++;
  }
  */

  // addresses
  maxValue += 4;
  if (checkIfObjectIsEmpty(profile.addresses) === false) {
    value += 4;
  }

  // communication-data
  maxValue += 4;
  if (checkIfObjectIsEmpty(profile.communicationData) === false) {
    value += 4;
  }

  // social-profiles
  maxValue++;
  if (checkIfObjectIsEmpty(profile.socialProfiles) === false) {
    value++;
  }

  // languages
  maxValue += 4;
  if (checkIfObjectIsEmpty(profile.languages) === false) {
    value += 4;
  }

  // skills
  maxValue += 3;
  if (checkIfObjectIsEmpty(profile.activitySkills) === false) {
    value += 3;
  }

  // schooling
  maxValue += 3;
  if (checkIfObjectIsEmpty(profile.schooling) === false) {
    value += 3;
  }

  // work-experiences
  maxValue += 3;
  if (checkIfObjectIsEmpty(profile.workExperiences) === false) {
    value += 3;
  }

  // promotion-experiences
  maxValue += 5;
  if (checkIfObjectIsEmpty(profile.promotionExperiences) === false) {
    value += 5;
  }

  // profileImagePortrait
  maxValue += 5;
  if (profile.profileImagePortrait) {
    value += 5;
  }

  // profileVideo
  maxValue += 5;
  if (profile.profileVideoIntroduction) {
    value += 5;
  }

  const percent = Math.floor((value / maxValue) * 100);

  return {
    maxValue,
    value,
    percent,
  };
}

export function checkUserPreferences(profile) {
  return !!(
    profile.preferredSalary ||
    checkIfObjectIsEmpty(profile.preferredActivities) === false ||
    checkIfObjectIsEmpty(profile.preferredIndustries) === false ||
    checkIfObjectIsEmpty(profile.addresses) === false
  );
}

export function calculatePersonalDataCompleteness(profile) {
  return !!(
    profile.firstName &&
    profile.lastName &&
    profile.gender &&
    profile.birthDate &&
    profile.nationality
  );
}

export function calculateAddressesCompleteness(profile) {
  return checkIfObjectIsEmpty(profile.addresses) ? false : true;
}

export function calculateJobEmploymentCompleteness(profile) {
  return !!(
    profile.jobEmploymentStatus &&
    profile.jobEmploymentStatusDateFrom &&
    (profile.jobEmploymentStatusDateUntil ||
      profile.jobEmploymentStatusOpenEnded)
  );
}

export function calculateCommunicationDataCompleteness(profile) {
  return checkIfObjectIsEmpty(profile.communicationData) ? false : true;
}

export function calculateSocialProfilesCompleteness(profile) {
  return checkIfObjectIsEmpty(profile.socialProfiles) ? false : true;
}

export function calculateAppearanceCompleteness(profile) {
  return !!(
    profile.bodyHeight &&
    profile.sizeOfClothing &&
    profile.sizeOfShirt &&
    profile.sizeOfShoe &&
    profile.sizeOfPants &&
    profile.hairColor
  );
}

export function calculateMobilityCompleteness(profile) {
  return (
    profile.ownCar || checkIfObjectIsEmpty(profile.drivingLicenses) === false
  );
}

export function calculateBankDataCompleteness(profile) {
  return !!(
    profile.accountHolder &&
    profile.bankName &&
    profile.iban &&
    profile.bic
  );
}

export function calculateSchoolingCompleteness(profile) {
  return checkIfObjectIsEmpty(profile.schooling) ? false : true;
}

export function calculatePromotionExperiencesCompleteness(profile) {
  return checkIfObjectHasMoreThen4Items(profile.promotionExperiences);
}

export function calculatePromotionExperiencesCompletenessForIncompleteAlert(
  profile
) {
  return checkIfObjectIsEmpty(profile.promotionExperiences) ? false : true;
}

export function calculateJobEmploymentCompletenessForIncompleteAlert(profile) {
  return profile.jobEmploymentStatus != null ? true : false;
}

export function calculateActivitySkillsCompletenessForIncompleteAlert(profile) {
  return profile.hasActivitySkills != null ? true : false;
}

export function calculateLanguagesCompleteness(profile) {
  return checkIfObjectIsEmpty(profile.languages) ? false : true;
}

export function calculateWorkExperiencesCompleteness(profile) {
  return checkIfObjectIsEmpty(profile.workExperiences) ? false : true;
}

export function calculateActivitySkillsCompleteness(profile) {
  return checkIfObjectIsEmpty(profile.activitySkills) ? false : true;
}

export function calculateWorkPreferenceCompleteness(profile) {
  return !!(
    profile.preferredSalary &&
    profile.preferredActivities &&
    profile.preferredActivities[0] &&
    profile.preferredIndustries &&
    profile.preferredIndustries[0] &&
    profile.preferredWorkingTimes &&
    profile.preferredWorkingTimes[0] &&
    profile.preferredWorkingPlaces &&
    profile.preferredWorkingPlaces[0] &&
    profile.preferredEmploymentTypes &&
    profile.preferredEmploymentTypes[0]
  );
}

export function calculateWorkPreferenceCompletenessForIncompleteAlert(profile) {
  return !!(profile.preferredSalary && profile.preferredActivities[0]);
}

export function calculateTaxCompleteness(profile) {
  return !!(
    (profile.taxNumber && profile.taxOffice) ||
    profile.salesTaxIdentificationNumber
  );
}

export function calculateImageCompleteness(profile) {
  return checkIfObjectIsEmpty(profile.profileImagePortrait) ? false : true;
}

export function calculateVideoCompleteness(profile) {
  return checkIfObjectIsEmpty(profile.profileVideoIntroduction) ? false : true;
}

export function calculateDocumentCompleteness(profile) {
  return !!(
    checkIfObjectIsEmpty(profile.profileDocumentCertificateOfEnrollment) ===
      false ||
    checkIfObjectIsEmpty(profile.profileDocumentBusinessLicense) === false ||
    checkIfObjectIsEmpty(profile.profileDocumentsMisc) === false
  );
}

export function hasRelevantDataForApplication(profile) {
  return !!(
    profile.firstName &&
    profile.lastName &&
    profile.gender &&
    profile.hasActivitySkills != null &&
    calculateAddressesCompleteness(profile) &&
    calculateCommunicationDataCompleteness(profile) &&
    checkIfObjectIsEmpty(profile.languages) === false &&
    profile.jobEmploymentStatus
  );
}
